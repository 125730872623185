import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import logo from "../images/360logo2.png"
import competencies from "../images/GLA-Competency-Descriptions.pdf"
import values from "../images/BKM_Personal_Values.pdf"
import leadership from "../images/Brittany_Macdonald_Authentic_Leadership_Model.pdf"
import culture from "../images/CulturePulse.png"
import support from "../images/support.png"

const CulturePulse = () => (
	<Layout>
		<SEO
			title="Culture Of Engagement – How To Build A Successful Team"
			description="AGL Coaching for Good can help you foster a culture of engagement within you organization. Contact us today to learn how to build a successful team."
		/>
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h1>
						<strong>Develop A Culture of Engagement with Culture Pulse!</strong>
					</h1>
				</div>
				<div className="my-5 text-center">
                  <Link to="/#get-coaching">
                    <span className="hero-btn2 align-items-center sched-modal">
                      Get Coaching
                    </span>
                  </Link>
                </div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto mb-5">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={culture}
							alt="Engager Icon"
						/>
					</div>
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0">
										<strong>
											Your team is the most important asset you have.{" "}
										</strong>{" "}
									</h2>
									<p className="">
										They ARE your company. They champion your vision. They bring
										the skills to make that vision a reality. They are your most
										important customer and have every opportunity to make -- or
										break -- your business. We want to help you build a
										successful team to help your business soar!
									</p>
									<p className="">
										Yet according to Gallup, most teams struggle with
										disengagement. Up to 52% of employees are considered
										“disengaged,” costing businesses billions of dollars a year.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content culture-bg">
			<div className="container">
				<div className="row justify-content-between pt-5 p-2">
					<div className="col-md-6 mx-auto">
						<h2 className="mb-0	text-white">
							<strong>
								Do you ever notice members of your team feeling overwhelmed?
								Stressed? Disconnected? Do they struggle with clear
								communication or meeting deadlines?
							</strong>{" "}
						</h2>
						<p className="text-white">
							Culture Pulse is here to help develop a culture of engagement
							within your team.
						</p>
						<p className="text-white">
							Through our exclusive 2-day event, we support you in leading your
							team through a series of team-building exercises, adventure
							activities, a celebration of strengths, and more all aimed at the
							end goal of building a more successful team.
						</p>
						<p className="text-white">
							By focusing on your team’s existing strengths, connecting them to
							individual and company values, and teaching team members to
							effectively manage their emotions and thoughts in the workplace,
							your team can become even more effective at what they do.
						</p>
						<p className="text-white">
							Reserve Your Dates Today! Our Culture Pulse service starts at
							$5000.
						</p>
					</div>
					<div className="col-md-6 mx-auto"></div>
				</div>
			</div>
		</div>
		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto mb-5">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={support}
							alt="Engager Icon"
						/>
					</div>
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0 text-gold">
										<strong>Results</strong>{" "}
									</h2>
									<p className="">
										Through Culture Pulse, we have helped dozens of clients
										increase their profits, expand their client base, and build
										a more engaged and effective team. Through COVID, every team
										that held a Culture Pulse event said their team left feeling
										more engaged and inspired. The numbers prove the results,
										Culture Pulse will help you build a successful team!
									</p>
									<p className="">
										Without intentional investment and team building, team
										members can easily default to making negative assumptions in
										the workplace, project their personal thoughts or feelings
										onto other team members, feel disconnected, burned out, and
										frustrated. Disengaged teams are less likely to think
										creatively, more likely to experience communication
										challenges, and ultimately, less likely to stay at their
										job, building your company vision. Let us help you foster a
										culture of engagement in your organization.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content dynamic-bg">
			<div className="container">
				<div className="row justify-content-between pt-5 p-2">
					<div className="col-md-6 mx-auto">
						<h2 className="mb-0	text-white">
							<strong>
								It’s time for you to lead a dynamic, successful team that fully
								engages every day at work, lives in their values, operates in
								their strengths, and makes your business the best it’s ever
								been.{" "}
							</strong>{" "}
						</h2>
						<p className="text-white mb-5">
							Take the leap to building a better, more successful and engaged
							team. Contact us for your free initial consultation.
						</p>
						<Link to="/#get-coaching">
							<span className="schedule-btn2 top-btn-sched mr-3 sched-modal mt-5">
								&nbsp;Get Coaching
							</span>
						</Link>
						
					</div>
					<div className="col-md-6 mx-auto"></div>
				</div>
			</div>
		</div>
		{/*}		<div className="page-content">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-3 mx-auto" />
					<div className="col-md-8 mx-auto">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-md-12">
									<h2 className="mb-0 text-gold">
										<strong>Create a Roadmap</strong>{" "}
									</h2>
									<p className="">
										At the end of your time, you will develop a roadmap to
										encapsulate your newfound wisdom into one page to guide you
										on the path ahead.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="page-content bg-gold">
			<div className="container">
				<div className="row justify-content-between pt-5">
					<div className="col-md-10 mx-auto">
						<div className="text-center">
							<h2 className="mb-0	text-white">
								<strong>The Bottom Line</strong>{" "}
							</h2>
							<p className="text-white">
								We use the Global Leadership Assessment 360 program. Here are
								the practical tools and opportunities you will receive as a part
								of the program.
							</p>
							<p className="text-left">
								<ul className="mt-5">
									<li>
										<p className="text-white">Unlimited Surveys for 1 Month</p>
									</li>
									<li>
										<p className="text-white">Feedback in 15 Competencies</p>
									</li>
									<li>
										<p className="text-white">
											60 Page Report Outlining Wins and Areas for Growth
										</p>
									</li>
									<li>
										<p className="text-white">6 Page Summary for Easy Review</p>
									</li>
									<li>
										<p className="text-white">
											Up to 4 Hours of One-on-One Coaching
										</p>
									</li>
									<li>
										<p className="text-white">
											Understand and Hone Your Core Values{" "}
										</p>
									</li>
									<li>
										<p className="text-white">
											Develop Your Roadmap: An Authentic Leadership Model{" "}
										</p>
									</li>
								</ul>
							</p>
							<h4 className="text-white mb-3">
								Please find the GLA360 Competencies along with sample reports
								and models below.
							</h4>
							<div class="pricing-table mt-3">
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											GLA360
											<br /> Competencies&nbsp;
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={competencies}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											Sample GLA360
											<br /> Values&nbsp;
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={values}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>
								<div class="pricing-option">
									<p>
										<h6 class="pricing-h">
											Sample GLA360 Authentic Leadership Model
										</h6>
									</p>

									<div class="price">
										<div class="back">
											<a
												class="bttn-b btn-prim-b btn-link-b"
												href={leadership}
												target="_blank"
												rel="noopener noreferrer"
												download
											>
												<span class="schedule-btn top-btn-sched sched-modal">
													Download
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>*/}
	</Layout>
)

export default CulturePulse
